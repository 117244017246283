import React from "react";
import Card from "../card";
import "./contactCard.css";

export default class ContactCard extends React.Component {
  render() {
    let comp = (
      <div class="contactRow">
        <a
          className="cLink"
          href={
            "mailto:" +
            this.props.email +
            "?subject=Hi " +
            this.props.name +
            "!"
          }
        >
          Contact to me.
        </a>
      </div>
    );

    return <Card comp={comp}></Card>;
  }
}
