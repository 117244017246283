import React from "react";
import Card from "../card";
import "./titleCard.css";

export default class TitleCard extends React.Component {
  render() {
    let comp = (
      <div className="titleRow">
        <span className="title">
          Hi, I'm <strong>Omer</strong>
        </span>
        <span className="subTitle">Backend Developer</span>
      </div>
    );

    return <Card comp={comp}></Card>;
  }
}
