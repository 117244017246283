import React from "react";
import ContactCard from "../contactCard";
import SocialCard from "../socialCard";
import TitleCard from "../titleCard";
import "./main.css";

export default class Main extends React.Component {
  render() {
    return (
      <div className="decorator main">
        <TitleCard name="Omer"></TitleCard>
        <SocialCard
          githubAcc="kaplanomr"
          linkedinAcc="kaplanomr"
          twitterAcc="kaplanomr"
        ></SocialCard>
        <ContactCard name="Omer" email="i@kaplanomer.com"></ContactCard>
      </div>
    );
  }
}
